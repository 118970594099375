import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function Footer() {
  return (
    <footer className="bg-dark text-white py-3">
      <Container>
        <Row>
          <Col className="text-center">
            <p>&copy; 2023 DappsAmerica. All Rights Reserved.</p>
            <p>
              Contact:{" "}
              <a href="mailto:dappsamericasrl@gmail.com">
                dappsamericasrl@gmail.com
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
