import React from "react";
import { Carousel } from "react-bootstrap";

const images = [
  {
    src: "https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    alt: "Image 1",
    caption: "Discover the best products with our company",
  },
  {
    src: "https://images.unsplash.com/photo-1517430816045-df4b7de11d1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
    alt: "Image 2",
    caption: "Experience the power of blockchain with our",
  },
  {
    src: "https://images.unsplash.com/photo-1521790797524-b2497295b8a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    alt: "Image 3",
    caption: "Get amazing deals with our company",
  },
];

export const CarouselComponent = () => {
  return (
    <Carousel interval={3000} pauseOnHover={true} fade={true}>
      {images.map((image) => (
        <Carousel.Item key={image.src}>
          <img
            className="d-block w-100"
            src={image.src}
            alt={image.alt}
            style={{ maxHeight: "300px", objectFit: "cover" }}
          />
          <Carousel.Caption>
            <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
              {image.caption}
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
