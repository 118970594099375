import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import "./App.css";
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  Image,
  Card,
} from "react-bootstrap";
import { Footer } from "./components/footer";
import { CarouselComponent } from "./components/carousel";

const App = () => {
  return (
    <>
      <div className="bg-light">
        {/* Navbar */}
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">DappsAmerica</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav">
              <Nav className="ml-auto">
                {/* <Nav.Link href="#">Home</Nav.Link> */}
                {/* <Nav.Link href="#">About</Nav.Link>
                <Nav.Link href="#">Services</Nav.Link> */}
                <Nav.Link href="mailto:dappsamericasrl@gmail.com">
                  dappsamericasrl@gmail.com
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="py-5">
          <Row>
            <Col md={8}>
              <h1 className="display-4">Welcome to Dapps America</h1>
              <p className="lead">
                We are a software development company that uses blockchain
                technology to build scalable and efficient software solutions.
              </p>
              <Button className="my-3" variant="primary">
                Learn more
              </Button>
            </Col>
            <Col md={4}>
              <img
                src="https://images.unsplash.com/photo-1570215171323-4ec328f3f5fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                alt="Dappsamerica logo"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        {/* Hero section */}
        <div className="bg-dark text-light py-5">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <h1 className="display-4">Build with Blockchain</h1>
                <p className="lead">
                  Dappsamerica provides new and state-of-the-art solutions to
                  problems using blockchain technology.
                </p>
                <Button className="my-3" variant="primary">
                  Get started
                </Button>
              </Col>
              <Col md={6}>
                <img
                  src="https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  alt="Descriptive"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container className="my-3">
        <Row xs={1} md={2} lg={2} xl={4} gutter={16}>
          <Col>
            <Card style={{ height: "100%" }}>
              <Card.Img
                style={{ objectFit: "cover", width: "100%", height: "50%" }}
                variant="top"
                src="https://images.unsplash.com/photo-1593720213428-28a5b9e94613?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              />
              <Card.Body>
                <Card.Title>Web Development</Card.Title>
                <Card.Text>
                  We offer custom web development services to help you create a
                  website that meets your specific needs.
                </Card.Text>
                {/* <Button variant="primary">Learn More</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ height: "100%" }}>
              <Card.Img
                style={{ objectFit: "cover", width: "100%", height: "50%" }}
                variant="top"
                src="https://images.unsplash.com/photo-1607270788732-55d2cdb8f52a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              />
              <Card.Body>
                <Card.Title>Mobile Development</Card.Title>
                <Card.Text>
                  Our mobile development services can help you create a mobile
                  app that engages your audience and drives growth.
                </Card.Text>
                {/* <Button variant="primary">Learn More</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ height: "100%" }}>
              <Card.Img
                style={{ objectFit: "cover", width: "100%", height: "50%" }}
                variant="top"
                src="https://images.unsplash.com/photo-1558655146-d09347e92766?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
              />
              <Card.Body>
                <Card.Title>UI/UX Design</Card.Title>
                <Card.Text>
                  Our UI/UX design services can help you create a user-friendly
                  interface that provides a seamless user experience.
                </Card.Text>
                {/* <Button variant="primary">Learn More</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ height: "100%" }}>
              <Card.Img
                style={{ objectFit: "cover", width: "100%", height: "50%" }}
                variant="top"
                src="https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80"
              />
              <Card.Body>
                <Card.Title>Consulting</Card.Title>
                <Card.Text>
                  We help you decide on the best technologies possible to make
                  your idea a functional and scalable piece of software!
                </Card.Text>
                {/* <Button variant="primary">Learn More</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="bg-light">
        <Container className="py-5">
          <CarouselComponent />
        </Container>
      </div>

      <Footer />
    </>
  );
};
export default App;
